var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal_design" }, [
    _c("div", { staticClass: "cancel-btn" }, [
      _c("img", {
        attrs: { src: "/img/cancel.png", alt: "" },
        on: {
          click: function ($event) {
            return _vm.$emit("closeModal")
          },
        },
      }),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal_wrap" }, [
      _c("div", {
        staticClass: "calendly-wrapper",
        staticStyle: { "max-width": "100%", height: "90vh" },
        attrs: { id: "SAMPLEdivID" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }