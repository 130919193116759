<template>
  <div class="modal_design">
    <div class="cancel-btn">
      <img @click="$emit('closeModal')" src="/img/cancel.png" alt="" />
    </div>
    <div class="modal_wrap">
      <div
        id="SAMPLEdivID"
        class="calendly-wrapper"
        style="max-width: 100%; height: 90vh"
      ></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: [
    "teamInfo",
    "client",
    "firstMeeting",
    "user",
    "teamLead",
    "meetingName",
    "closeModal",
    "meetLink",
  ],
  data: () => ({
    defaultMeetingUrl: "https://calendly.com/grupa_inc/30min",
    url: "",
  }),
  created() {
    // localStorage.clear();
    let meetingUrl = this.meetLink ? this.meetLink : this.defaultMeetingUrl;
    if (this.client) {
      let meetingName = "";
      if (this.meetingName) {
        meetingName = this.meetingName;
      }
      this.url = `${meetingUrl}?name=${meetingName}&email=${this.user.email}`;
    } else if (this.firstMeeting) {
      this.url = `${meetingUrl}?name=${this.user.name}&email=${this.user.email}`;
    } else if (this.teamLead) {
      let meetingName = `Meet The Team Lead`;
      this.url = `${meetingUrl}?name=${meetingName}&email=${this.user.email}`;
    } else {
      let meetingName = `${this.teamInfo.team.team_name} <> Grupa: Onboarding meeting`;
      this.url = `${meetingUrl}?name=${meetingName}&email=${this.teamInfo.user.email}`;
    }
  },
  async mounted() {
    this.initCalendly();
  },
  methods: {
    initCalendly() {
      Calendly.initInlineWidget({
        url: this.url,
        parentElement: document.getElementById("SAMPLEdivID"),
        prefill: {},
        utm: {},
      });
    },
    getCalendly() {
      var list = document.getElementById("SAMPLEdivID").childNodes;
      let calendlyIframe = list[1];
      let contentIframe =
        calendlyIframe.contentDocument || calendlyIframe.contentWindow.document;
    },
  },
};
</script>

<style scoped>
.modal_design {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
}
.cancel-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
}
.cancel-btn img {
  height: 40px;
  widows: 40px;
  cursor: pointer;
  background: white;
  border-radius: 20px;
  margin: 2% 3% 0 0;
  position: absolute;
}
.modal_wrap {
  /* background: #ffffff;
  box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  /* flex-direction: column; */
  width: 100%;
  min-height: 70vh;
  /* box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px; */
}
.head_div {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #15171a;
  box-shadow: inset 0px -1px 0px rgba(33, 33, 33, 0.15);
  border-radius: 4px 4px 0px 0px;
  padding: 15px;
}
.spinnerBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.calendly-wrapper {
}
</style>
<style>
.embedDoc ._2LpwQ___Wrapper__cls1 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
</style>

